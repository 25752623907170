import * as React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ScrollDivider = ({ size, anchor, color, button }) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1984 326"
      preserveAspectRatio="none"
      className="divider"
    >
      <defs>
        <style>
          {`.cls-1{fill: `}
          {color}
          {`;}`}
        </style>
      </defs>
      <title>Asset 1</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M886.17,13.31,0,241.5V326H1984V241.5L1097.83,13.31a427.47,427.47,0,0,0-211.66,0"
          />
        </g>
      </g>
    </svg>
    {button && (
      <AnchorLink to={anchor} className="scroll-button round-button">
        <div className="round">
          <div className="arrow"></div>
        </div>
      </AnchorLink>
    )}
  </>
);

ScrollDivider.propTypes = {
  size: PropTypes.number,
  anchor: PropTypes.string,
  color: PropTypes.string,
  button: PropTypes.bool,
};

export default ScrollDivider;
