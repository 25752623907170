import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const PakketRollItem = ({ post }) => {
  return (
    <div className="is-parent column is-4">
      <Link to={post.fields.slug.slice(0, -1)}>
        <article className={`blog-list-item tile is-child`}>
          <header>
            {post.frontmatter.featuredimage ? (
              <div className="featured-thumbnail">
                <div className="featured-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `Afbeelding voor pakket ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                </div>
                <div
                  className={`featured-text ${
                    !post.frontmatter.active ? "closed" : ""
                  }`}
                >
                  {!post.frontmatter.active && (
                    <div className="featured-text-closed">gesloten</div>
                  )}
                  €{post.frontmatter.price},-
                </div>
              </div>
            ) : null}
            <p className="post-meta">
              <span className="title">{post.frontmatter.title}</span>
              <span className="subtitle margin-top-0 is-block">
                “{post.frontmatter.heading}”
              </span>
            </p>
          </header>
          <div className="columns">
            <div className="column is-10 is-vcentered">
              <hr className="solid"></hr>
            </div>
            <div className="column is-2 is-vcentered">
              <div
                to={post.fields.slug.slice(0, -1)}
                className="round-button go-button small-button"
              >
                <div className="round">
                  <div className="arrow"></div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Link>
    </div>
  );
};

const PakketRollTemplate = (props) => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="columns is-multiline">
      {posts &&
        posts.map(({ node: post }) => (
          <PakketRollItem post={post} key={post.id} />
        ))}
    </div>
  );
};

PakketRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function PakketRoll() {
  return (
    <StaticQuery
      query={graphql`
        query PakketRollQuery {
          allMarkdownRemark(
            sort: {
              order: [DESC, DESC]
              fields: [frontmatter___active, frontmatter___price]
            }
            filter: { frontmatter: { templateKey: { eq: "pakket-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  price
                  heading
                  active
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        height: 500
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <PakketRollTemplate data={data} count={count} />}
    />
  );
}
