import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import PakketRoll from "../../components/PakketRoll";
import FullWidthImage from "../../components/FullWidthImage";
import ScrollDivider from "../../components/ScrollDivider";
import { AnchorLink } from "gatsby-plugin-anchor-links";

function PakketIndexPage(props) {
  const reasons = [
    {
      index: 1,
      number: "1.",
      value:
        "Door nu een bedrag per maand in te leggen, voorkomt u dat u straks noodgedwongen de hele maand op pasta met ketchup moet leven.",
    },
    {
      index: 2,
      number: "2.",
      value:
        "Bij het kiezen van een spaarplan denkt u misschien eerder aan een kleiner maandelijks bedrag, maar met het later opwaarderen middels losse kaartverkoop bent u stukken duurder uit; daar kunnen we u van verzekeren. Zoals aangegeven bij de plannen is te zien hoe veel je in totaal (en percentueel) bespaard met het afsluiten van een spaarplan t.o.v. losse verkoop. Daarnaast spaart u tevens voor unieke lustrumartikelen die niet in de losse verkoop zullen gaan.",
    },
    {
      index: 3,
      number: "3.",
      value:
        "Herkent u dat gevoel? Hoe u vroeger als enige kind uit de buurt niet een spectaculair vuurwerkpakket had? En hoe u dan – terwijl u met een schuine oog naar uw burenvriendjes keek die met de ene na de andere Cobra de buurt terroriseerden – beteuterd enkele knalerwtjes op de grond gooide? Zegt u nu eens zelf, dat wilt u toch voorkomen? ",
    },
  ];

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "paketten.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const heroImage = getImage(data.file);

  return (
    <Layout>
      <FullWidthImage
        img={heroImage}
        title={"pakketten"}
        height={"80vh"}
        color="#E8E100"
        imgPosition="bottom"
      />
      <ScrollDivider color={"#00412D"} button={true} anchor="/pakketten#main" />
      <section className="section" style={{ background: "#00412D" }}>
        <div className="container">
          <div className="content">
            <div className="columns is-multiline">
              <div className="column is-8 is-offset-2">
                <div className="section has-text-centered theme-italic"></div>
                <div className="section content has-text-centered theme-italic promote">
                  <p>
                    Vanaf de dies op 28 oktober zullen gedurende het jaar
                    verschillende kleine en grote evenementen plaatsvinden. Het
                    hoogtepunt van deze reeks activiteiten zal plaatsvinden in
                    de zomer van 2023, tijdens de Lustrumweek.
                  </p>
                  <p>
                    De lustrum commissie heeft speciaal voor eerstejaars een
                    drietal spaarplannen samengesteld zodat u vanaf deze maand
                    al kunt beginnen met sparen. Zo bent u verzekerd van kaarten
                    voor de mooiste evenementen en krijgt u de beste prijs voor
                    exclusieve lustrum items!
                  </p>

                  <p>Voor een oud-ledenpakket klik op de onderstaande link!</p>

                  <div className="columns">
                    <div className="column is-6">
                      <AnchorLink
                        to="/pakketten#main"
                        className="promote-button center white"
                      >
                        PAKKETTEN VOOR EERSTEJAARS
                      </AnchorLink>
                    </div>
                    <div className="column is-6">
                      <Link
                        to="/pakketten/oudlid"
                        className="promote-button center white"
                      >
                        PAKKETTEN VOOR OUD-LEDEN
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="solid" id="main"></hr>
            <PakketRoll />
            <hr className="solid"></hr>
            <div className="has-text-centered">
              <p className="reason-title is-size-3">
                INDIEN U NOG NIET <br /> OVERTUIGD BENT
              </p>
            </div>
            <div className="columns">
              {reasons.map((reason, i) => (
                <div className="column is-4" key={i}>
                  <div
                    className={`blog-list-item reason-list has-text-centered`}
                  >
                    <span className="title">{reason.number}</span>
                    <span className="content">{reason.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
export default PakketIndexPage;
